import React, { useContext, useEffect, useState } from "react";
//import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import clsx from "clsx";
import { WrapperContext } from "./Layout";
import Menu from "./Menu";
import LocaleSwitcher from "./ui/LocaleSwitcher";
import Burger from "./ui/Burger";
import Logo from "../images/baek-books-logo.inline.svg";

const Header = ({ direction }) => {
  const _WrapperContext = useContext(WrapperContext);
  const { settings } = _WrapperContext;
  const [menuActive, setMenuActive] = useState();

  useEffect(() => {
    const isMobile = window.innerWidth < 1080 && "ontouchstart" in window;

    const token = PubSub.subscribe("BURGER", (e, d) => {
      console.log(d);
      // console.log("isMobile", isMobile);
      if (isMobile) setMenuActive(d);
    });

    return () => PubSub.unsubscribe(token);
  }, []);

  // console.log(settings);
  // const menuContainerClass = menuActive
  return (
    <header
      className={clsx(
        "px-_md md:p-lg text-_md md:text-md",
        direction,
        menuActive ? "is-menu-active" : ""
      )}
    >
      <div className="row between-xs">
        <div className="col-md-1 col-xs-12">
          <div className="logo">
            <div className="inner">
              <Link to="/">
                <Logo />
                <div className="site-name absolute text-lg lowercase">
                  {settings.data.title.text}
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xs-6 hidden-md"></div>
        <div className="col-md-2 col-xs-6">
          <div
            className={clsx(
              "menu-container hidden md:block",
              menuActive ? "is-active" : ""
            )}
          >
            <LocaleSwitcher />
            <Menu />
          </div>
        </div>
      </div>
      <Burger />
    </header>
  );
};

export default Header;
