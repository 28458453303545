import React, { useRef, useState } from "react";
import { navigate } from "gatsby";

const SearchForm = () => {
  const inputRef = useRef();
  const [term, setTerm] = useState();

  const _onChange = (event) => {
    const searchValue = event.target.value;

    // setSearchUrl(searchValue);
    setTerm(searchValue);
  };

  const _onSubmit = (event) => {
    event.preventDefault();

    navigate(`/search?s=${term}`, { replace: true });
  };

  return (
    <form
      onSubmit={_onSubmit}
      className="search-form text-_xs md:text-xs"
      onMouseEnter={() => inputRef.current.focus()}
    >
      <input
        type="text"
        id="search"
        placeholder="Search pages..."
        value={term}
        onChange={_onChange}
        ref={inputRef}
      />
      <button type="submit">
        <svg
          width="36"
          height="48"
          viewBox="0 0 36 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.7642 16.5566C29.7642 24.3815 23.6 30.6132 16.1321 30.6132C8.66411 30.6132 2.5 24.3815 2.5 16.5566C2.5 8.73174 8.66411 2.5 16.1321 2.5C23.6 2.5 29.7642 8.73174 29.7642 16.5566Z"
            stroke="#7A7171"
            strokeWidth="5"
          />
          <line
            x1="33.58"
            y1="46.3868"
            x2="23.3913"
            y2="31.1037"
            stroke="#7A7171"
            strokeWidth="5"
          />
        </svg>
      </button>
    </form>
  );
};

export default SearchForm;
