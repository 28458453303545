import React from "react";
import SearchForm from "../components/ui/SearchForm";

const Footer = () => (
  <footer className="p-md">
    <div className="row between-xs">
      <div className="col-xs col-xs-12">
        <div className="copyright">© {new Date().getFullYear()} </div>
      </div>
      <div className="col-md-2 col-xs-12">
        {/* <p>
            Made with ❤ by the{" "}
            <a href="ahmedghazi.com" target="_blank">
              a_e_a_i_
            </a>
          </p> */}
        <SearchForm />
      </div>
    </div>
  </footer>
);

export default Footer;
