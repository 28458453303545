//const website = require('./website')

module.exports = {
  "ko-kr": {
    default: false,
    label: "한글",
    path: "kr",
    locale: "ko-kr",
    siteLanguage: "kr",
    ogLang: "ko_KR",
    donwload: "dl"
  },
  "fr-fr": {
    default: true,
    label: "Fr",
    path: "fr",
    locale: "fr-fr",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    donwload: "télécharger"
  }
};
