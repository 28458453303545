import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";

const WrapperContext = React.createContext();

const query = graphql`
  query {
    settings: prismicSettings {
      ...settings
    }
  }
`;

const Layout = ({ children, pageContext }) => {
  const lang = pageContext ? pageContext.lang : "fr-fr";
  const { settings } = useStaticQuery(query);
  // console.log(settings)
  const [direction, setDirection] = useState();

  useEffect(() => {
    _onScroll();
    _format();
    window.addEventListener("scroll", _onScroll);
    window.addEventListener("resize", _format);
    // if (window.innerWidth < 1080 && "ontouchstart" in window) setIsMobile(true)

    return () => {
      window.removeEventListener("scroll", _onScroll);
      window.removeEventListener("resize", _format);
    };
  }, []);

  let _prevScrollTop = 0;
  const _onScroll = () => {
    window.pageYOffset > _prevScrollTop
      ? setDirection("down")
      : setDirection("up");

    if (window.pageYOffset === 0) {
      setDirection("");
    }

    _prevScrollTop = window.pageYOffset;
  };

  const _format = () => {
    const isMobile = window.innerWidth < 1080 && "ontouchstart" in window;
    // if (window.innerWidth < 982 && "ontouchstart" in window) setIsMobile(true);
    // console.log("format");
    // const ww = window.innerWidth;
    // const wh = window.innerHeight;
    document.documentElement.style.setProperty(
      "--appHeight",
      window.innerHeight + "px"
    );

    let headerHeight = "208";
    const currentMenuItem = document.querySelector(
      "header nav a[aria-current]"
    );
    if (isMobile) {
      headerHeight = document
        .querySelector("header")
        .getBoundingClientRect().height;
    } else if (currentMenuItem) {
      const headerMenuItemBounding = currentMenuItem.getBoundingClientRect();
      headerHeight = headerMenuItemBounding.top + headerMenuItemBounding.height;
    } else {
      headerHeight = document
        .querySelector("header")
        .getBoundingClientRect().height;
    }
    document.documentElement.style.setProperty(
      "--headerHeight",
      headerHeight + "px"
    );
    // console.log("top");
  };

  return (
    <WrapperContext.Provider value={{ settings, lang }}>
      <div id="page">
        <Header direction={direction} />
        <main className="px-_md md:px-lg">{children}</main>
        <Footer />
      </div>
    </WrapperContext.Provider>
  );
};

export { WrapperContext, Layout };
