const locales = require("../../config/i18n");
const website = require("../../config/website");

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf("/") + 1);
}

// export const _getHomeUrl = () => {
//   const locale = _getLocale();
//   return locales[locale].default ? `/` : `/${locales[locale]["path"]}`;
// };

export const _localizedSlug = (node) => {
  // console.log(website);
  // return "/";
  if (!node.uid) return "/";
  const link = _linkResolver(node);
  // console.log(link);
  return locales[node.lang].default
    ? `${link}`
    : `/${locales[node.lang].path}${link}`;
};

export const _linkResolver = (doc) => {
  // console.log(doc);
  switch (doc.type) {
    case "page":
      return `/${doc.uid}`;
    case "article":
      return `/a/${doc.uid}`;
    case "category":
      return `/c/${doc.uid}`;
    default:
      return "/";
  }
};

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function randomizePositions(i, el) {
  //console.log(el)
  const ww = window.innerWidth;
  const wh = window.innerHeight;
  const maxW = ww * 1.5 - 400;
  const radiusX = ww / 1;
  const radiusY = wh / 3;

  //const {width} = el.getBoundingClientRect()

  const centerX = ww / 2;
  const centerY = wh / 2;
  const minX = -radiusX;
  const maxX = radiusX;
  const minY = -radiusY;
  const maxY = radiusY;

  let randX = centerX + minMax(minX, maxX);
  let randY = centerY + minMax(minY, maxY);

  if (randX <= 0) randX = minMax(50, maxX);
  if (randX >= maxW) randX = minMax(centerX, maxW);

  if (randY <= 50) randY = 50;
  //console.log(randX)
  //console.log(this.refs.figure)
  //const style = {transform: 'translate('+randX+'px, '+randY+'px)'}
  //const {i} = this.props
  const _minW = Math.random() * (ww / 3);
  const _maxW = Math.random() * (ww / 2);

  const width = minMax(_minW, _maxW);
  const style = {
    width,
    left: randX + "px",
    top: randY + "px",
    transitionDelay: 0.2 + i / 20 + "s",
  };

  return style;
}

export function minMax(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
