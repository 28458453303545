import React, { useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import clsx from "clsx";
import { WrapperContext } from "./Layout";
import { _localizedSlug } from "../core/utils";
const query = graphql`
  query {
    menuFr: prismicMenu(uid: { eq: "menu-header" }, lang: { eq: "fr-fr" }) {
      ...menu
    }
    menuKr: prismicMenu(uid: { eq: "menu-header" }, lang: { eq: "ko-kr" }) {
      ...menu
    }
  }
`;

const Menu = () => {
  const { menuFr, menuKr } = useStaticQuery(query);
  const { lang } = useContext(WrapperContext);
  const menu = lang === "fr-fr" ? menuFr : menuKr;
  //locale slug
  return (
    <nav>
      <ul className="">
        {menu.data.menu_items.map((li, i) => (
          <li
            key={i}
            className={clsx(
              "menu-item",
              `menu-item-${li.item.uid}`,
              li.padding ? "py-sm" : ""
            )}
          >
            <Link to={_localizedSlug(li.item)} data-uid={li.item.uid}>
              {li.title.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
