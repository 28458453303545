import React, { useContext } from "react";
import { Link } from "gatsby";
import { WrapperContext } from "../Layout";
// import PubSub from "pubsub-js"
import clsx from "clsx";

// import { ContextHoc } from "../../context/ContextHoc"
const locales = require("../../../config/i18n");

const LocaleSwitcher = () => {
  // const _LocaleContext = useContext(LocaleContext)
  // const { localeCtx, dispatch } = _LocaleContext
  // console.log(_LocaleContext)

  const _WrapperContext = useContext(WrapperContext);
  const { lang } = _WrapperContext || "fr-fr";

  const renderLanguages = () => {
    // const { nav } = this.state
    // const { locale } = this.props
    // const otherLang = locales.filter(el => {
    //   return el.locale !== lang
    // })
    // console.log(locales)
    // console.log(otherLang)
    const languages = Object.values(locales).map((li, key) => {
      const isActive = li.locale === lang;

      // const _className = li.locale === lang ? "active" : ""
      const path = li.default ? `/` : `/${li.path}`;
      return (
        <li key={key} className={clsx("locale-item")}>
          <Link to={path} className={`${isActive} lowercase`}>
            <span>{li.label}</span>
            {key === 0 && <span className="sep ">_</span>}
          </Link>
        </li>
      );
    });
    return languages;
  };

  return (
    <ul className="locale-switcher flex text-_xs md:text-xs">
      {renderLanguages()}
    </ul>
  );
};

export default LocaleSwitcher;
